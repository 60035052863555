import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

export default function HonorPage(props) {
  return (
    <Layout>
      <Seo title="Honor in the West" />
      <div className="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">Honor in the West</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/honor/honor-front2.jpg"
              alt="Frontyard"
              placeholder="blurred"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2019</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">
                4 bed Enhanced Behavioral Supports Home (EBSH) for adults (18-59
                years of age)
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                Clean home design (PICA), 24-hour supervision, private rooms,
                security cameras, window/door chimes, social skills training,
                enhanced staffing/experienced staff, crisis prevention
                intervention, licensed Psychiatric Technician or Registered
                Behavior Technician on shift
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Planning and implementing treatment to developmentally disabled
                individuals who currently are or have a history of being
                involved in criminal activity, mental health problems,
                inappropriate sexual behavior, physical and/or verbal
                aggression, property destruction, violent outbursts, taunting
                and teasing, threats, self-injurious behavior (SIB), compulsive
                eating disorders, PICA, AWOL, and are court ordered into the
                facility.
              </p>
            </div>
          </div>
        </div>

        <h2 className="pictures-title">Facility Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-back1.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-back2.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-back4.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={600}
              height={800}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-back5.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={400}
              height={700}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-back6.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-back7.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-kitchen1.jpg"
              alt="Kitchen"
              placeholder="blurred"
              width={400}
              height={600}
            />
          </div>
          <StaticImage
            src="../images/facilities/honor/honor-kitchen2.jpg"
            alt="Kitchen"
            placeholder="blurred"
            width={400}
          />
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-living1.jpg"
              alt="Living Room"
              placeholder="blurred"
              width={400}
              height={600}
            />
          </div>
          <StaticImage
            src="../images/facilities/honor/honor-living3.jpg"
            alt="Living Room"
            placeholder="blurred"
            width={400}
          />
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-living4.jpg"
              alt="Living Room"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/honor/honor-living5.jpg"
              alt="Living Room"
              placeholder="blurred"
              width={400}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
